"use client";

import { useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import { v4 as uuidv4 } from 'uuid';
import { CSSProperties } from 'react';

export default function Error({
  error
}: {
  error: Error & { digest?: string }

}) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING, 
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      isBrowserLinkTrackingEnabled: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();

  // @ts-ignore
  
  const errId =  uuidv4();

  const containerStyle: CSSProperties = {
    height: '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  };

  const textStyle: CSSProperties = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '28px',
    margin: '0 8px',
  };

  useEffect(() => {
    appInsights.trackException({
        exception:  error,
        properties: {
          digest: error.digest,
          errorId: errId // Optional, if you want to track the digest property
        },
      });
  }, [error])

  return (
    <div style={containerStyle}>
    <div>
      <h2 style={textStyle}>
        Something went wrong! <br /> {error.message}
      </h2>
      <p style={textStyle}>
        Error ID: {errId}
      </p>
    </div>
  </div>
  )
}
